import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-wrapper.js";
import SEO from '@/components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact" mdxType="SEO" />
    <h1>{`Contact`}</h1>
    <p>{`General Questions? Email: `}<a parentName="p" {...{
        "href": "mailto:proudboysct@protonmail.com"
      }}>{`proudboysct@protonmail.com`}</a>{`.`}</p>
    <p>{`Press inquiries? Email: `}<a parentName="p" {...{
        "href": "mailto:proudboysctpress@gmail.com"
      }}>{`proudboysctpress@gmail.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      